<template>
  <div class="container">

    <el-row :gutter="20">
      <el-col :lg="4" :md="6" :sm="12" :xl="4" :xs="24">
        <el-card class="box-card" :title="getTitle(events.leads)">
          <div slot="header">
            <span>Лиды</span>
            <el-switch class="float-right" v-model="settings.leads" @change="save"></el-switch>
          </div>
          <el-row type="flex" justify="space-between" align="middle">
            <el-tag :type="getType(events.leads)">
              {{ events.leads }}
            </el-tag>
            <el-button type="primary" icon="el-icon-edit" circle disabled></el-button>
          </el-row>
        </el-card>
      </el-col>

      <el-col :lg="4" :md="6" :sm="12" :xl="4" :xs="24">
        <el-card class="box-card" :title="getTitle(events.contacts)">
          <div slot="header">
            <span>Контакты</span>
            <el-switch class="float-right" v-model="settings.contacts" @change="save"></el-switch>
          </div>
          <el-row type="flex" justify="space-between" align="middle">
            <el-tag :type="getType(events.contacts)">
              {{ events.contacts }}
            </el-tag>
            <el-button type="primary" icon="el-icon-edit" circle disabled></el-button>
          </el-row>
        </el-card>
      </el-col>

      <el-col :lg="4" :md="6" :sm="12" :xl="4" :xs="24">
        <el-card class="box-card" :title="getTitle(events.companies)">
          <div slot="header">
            <span>Компании</span>
            <el-switch class="float-right" v-model="settings.companies" @change="save"></el-switch>
          </div>
          <el-row type="flex" justify="space-between" align="middle">
            <el-tag :type="getType(events.companies)">
              {{ events.companies }}
            </el-tag>
            <el-button type="primary" icon="el-icon-edit" circle disabled></el-button>
          </el-row>
        </el-card>
      </el-col>

      <el-col :lg="4" :md="6" :sm="12" :xl="4" :xs="24">
        <el-card class="box-card" :title="getTitle(events.deals)">
          <div slot="header">
            <span>Сделки</span>
            <el-switch class="float-right" v-model="settings.deals" @change="save"></el-switch>
          </div>
          <el-row type="flex" justify="space-between" align="middle">
            <el-tag :type="getType(events.deals)">
              {{ events.deals }}
            </el-tag>
            <el-button type="primary" icon="el-icon-edit" circle disabled></el-button>
          </el-row>
        </el-card>
      </el-col>

      <el-col :lg="4" :md="6" :sm="12" :xl="4" :xs="24">
        <el-card class="box-card" :title="getTitle(events.meetings)">
          <div slot="header">
            <span>Встречи</span>
            <el-switch class="float-right" v-model="settings.meetings" @change="save"></el-switch>
          </div>
          <el-row type="flex" justify="space-between" align="middle">
            <el-tag :type="getType(events.meetings)">
              {{ events.meetings }}
            </el-tag>
            <el-button type="primary" icon="el-icon-edit" circle disabled></el-button>
          </el-row>
        </el-card>
      </el-col>

      <el-col :lg="20" :md="24">
        <el-card class="description">
          <div class="description-item">
            <el-tag type="success">Активна</el-tag>
            Передача данных активна - последняя передача состоялась менее 7 дней назад
          </div>
          <div class="description-item">
            <el-tag type="warning">В ожидании</el-tag>
            Передача данных в ожидании - последняя передача состоялась от 7 до 30 дней назад
          </div>
          <div class="description-item">
            <el-tag type="danger">Неактивна</el-tag>
            Передача данных неактивна - последняя передача состоялась более 30 дней назад
          </div>
          <div class="description-item">
            <i class="el-icon-info"></i>
            Проверка обновлений выполняется каждый час.
          </div>
          <div class="description-item">
            <i class="el-icon-warning-outline"></i>
            На почту <strong>kokhas.ra@gmail.com</strong> настроено уведомление о неактивном статусе передачи данных.
          </div>
          <div class="description-item">
            <i class="el-icon-document"></i>
            Документы по интеграции:<br>
            <el-link type="primary" target="_blank" href="https://docs.google.com/document/d/1YP3SK1Za3fqeX3w9u8ySn193ke9pTqYfV0-aFVnhCfg/edit">Интеграция с Сендсей: формы подписки</el-link><br>
            <el-link type="primary" target="_blank" href="https://docs.google.com/document/d/1qf63_CSQwEEPYu1VWFKyC9i7uAM_vLvRNFjwWMM_IqQ/edit">Интеграция с Сендсей: Битрикс24</el-link><br>
          </div>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import {saveSettings} from '../services/api';

export default {
  name: 'Main',
  mixins: [
  ],
  components: {
  },
  data() {
    return {
      saving: false,
    }
  },
  computed: {
    ...mapGetters({
      loading: 'getLoading',
      settings: 'getSettings',
      events: 'getEvents',
    }),
  },
  mounted() {

  },
  methods: {
    async save() {
      this.saving = true;
      this.$store.commit('setSettings', this.settings);
      await saveSettings({settings: this.settings});
      this.saving = false;
      this.$message.success('Настройки успешно сохранены');
    },
    getType(date) {
      if (date === '-') {
        return 'info';
      }
      const days = moment().diff(moment(date, 'DD.MM.YYYY'), 'days');
      if (days > 30) {
        return 'danger';
      }
      if (days > 7 && days <= 30) {
        return 'warning';
      }
      return 'success';
    },
    getTitle(date) {
      if (date === '-') {
        return 'Неактивна';
      }
      const days = moment().diff(moment(date, 'DD.MM.YYYY'), 'days');
      if (days > 30) {
        return 'Неактивна';
      }
      if (days > 7 && days <= 30) {
        return 'В ожидании';
      }
      return 'Активна';
    },
  }
};
</script>

<style scoped>
  .container {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-bottom: 10px;
    width: 99%;
  }
  .float-right {
    float: right;
    padding: 3px 0;
  }
  .description {
    margin-top: 10px;
  }
  .description-item {
    margin-bottom: 10px;
  }
</style>